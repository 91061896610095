import React , { useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout"
import UspSection from "../components/uspSection"
import BlockContent from '../components/block-content'
import BackgroundImage from 'gatsby-background-image'
import Form from "../components/form"
import Helmet from 'react-helmet'
import { FaMapMarkerAlt } from 'react-icons/fa'
import PortableText from '@sanity/block-content-to-react'
import $ from 'jquery'
import servicesBackground from "../images/servicesIconBg.jpg"
import App from '../App';
import CTA from "../components/cta"


function getUrlVars(){
  var vars = [], hash;
  if(typeof window !== 'undefined'){
      var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
      for(var i = 0; i < hashes.length; i++)
      {
          hash = hashes[i].split('=');
          vars.push(hash[0]);
          vars[hash[0]] = hash[1];
      }
  }
  return vars;
}
var city = getUrlVars()["city"];

if (city !== undefined){
  let cityDash = city;
  cityDash = cityDash.replace(/-/g, ' ');

    var cityplace = " in " + cityDash;
    var citytitle = cityDash+"'s";
} else{
  cityplace = "";
}

export const query = graphql`
    query pagesQuery {
        sanityPages(slug: {current: {eq: "home"}}) {
            pagetitle
            heroheader
            _rawHerocopy
            slug {
                current
            }
            usp1{
              uspTitle
              uspText
              icon
            }
            usp2{
                uspTitle
                uspText
                icon
            }
            usp3{
                uspTitle
                uspText
                icon
            }
            homeservicesIcon1{
              homeservicesTitle
              homeservicesText
              homeservicesLink
              homeservicesIcon{
                  asset {
                      fluid(maxWidth: 1920) {
                          ...GatsbySanityImageFluid
                      }
                  }
              }
            }
            homeservicesIcon2{
              homeservicesTitle
              homeservicesText
              homeservicesLink
              homeservicesIcon{
                  asset {
                      fluid(maxWidth: 1920) {
                          ...GatsbySanityImageFluid
                      }
                  }
              }
            }
            homeservicesIcon3{
              homeservicesTitle
              homeservicesText
              homeservicesLink
              homeservicesIcon{
                  asset {
                      fluid(maxWidth: 1920) {
                          ...GatsbySanityImageFluid
                      }
                  }
              }
            }
            homeservicesIcon4{
              homeservicesTitle
              homeservicesText
              homeservicesLink
              homeservicesIcon{
                  asset {
                      fluid(maxWidth: 1920) {
                          ...GatsbySanityImageFluid
                      }
                  }
              }
            }
            _rawFirstcopy
            _rawServices
            coupon {
                title
                type
            }
            heroimage {
                asset {
                    fluid(maxWidth: 1920) {
                        ...GatsbySanityImageFluid
                    }
                }
            }
            serviceimage {
                asset {
                    fluid(maxWidth: 1920) {
                      ...GatsbySanityImageFluid
                    }
                }
            }
        }
        sanityCompanyInfo {
          companyname
          companyTagline     
          homepageservicebackground{
              asset {
                  fluid{
                    ...GatsbySanityImageFluid
                    src
                  }
                }
          }
          primarycolor{
            hex
            rgb{
                a
                r
                g
                b
            }
          }
          secondarycolor{
              hex
              rgb{
                  a
                  r
                  g
                  b
              }
          }
          accentcolor{
              hex
              rgb{
                  a
                  r
                  g
                  b
              }
          }
          tertiarycolor{
            hex
            rgb{
                a
                r
                g
                b
            }
          }
          tertiaryaltcolor{
            hex
            rgb{
                a
                r
                g
                b
            }
          }
        }
        allSanitySocial{
          edges{
            node{
              socialproofTitle
              socialproofNumber
              }
            }
          }
    }
`
/* Get CURRENT DAY */
const now = new Date();
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const today = days[now.getDay()];

/* PRINT COUPON */
function printCoupon() {
  if(typeof window !== 'undefined'){
    window.print();
}
}
function changeActive(){
  $(".form").toggleClass("expanded");
  $('body').toggleClass('formExpanded');
}
/* GET THE CURRENT URL */


const IndexPage = ( {data }) => {
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());


    // Update the links with URL parameters
    document.querySelectorAll('.serviceLink').forEach((link) => {
    const serviceSlug = link.getAttribute('data-service-slug');
    const modifiedLink = `${serviceSlug}?${Object.entries(urlParams).map(([key, value]) => `${key}=${value}`).join('&')}`;
    link.href = modifiedLink;
    });
    
}, []);

return (
  <Layout>
    <Helmet>
      <title>{data.sanityCompanyInfo.companyname} | {data.sanityPages.pagetitle}</title>    
    </Helmet>
    <div className="popupForm"><Form /></div>

    <div className="row heroRow">
      <div className="leftSection">
        <div className="leftSectionText">
          <p className="tagline_marker" style={{color: data.sanityCompanyInfo.tertiarycolor.hex, textTransform: 'uppercase'}}><FaMapMarkerAlt/> {data.sanityCompanyInfo.companyTagline} {cityplace}</p>
          <span className="heroBlockTitle"><h2>{data.sanityPages.heroheader} {cityplace}</h2></span>
          <PortableText blocks={data.sanityPages._rawHerocopy} />
          <a href="#" onClick={changeActive} 
          style={{ backgroundColor: data.sanityCompanyInfo.accentcolor.hex, borderColor: data.sanityCompanyInfo.accentcolor.hex }}
          > Schedule Service Today</a>
        </div>
      </div>
      <div className="rightSection">
        <BackgroundImage 
        style={{height: "100%", backgroundSize: "cover"}}
        fluid={data.sanityPages.heroimage.asset.fluid}>
        </BackgroundImage>
      </div>
    </div>


    <div className="coupon_section" style={{backgroundColor: data.sanityCompanyInfo.tertiarycolor.hex}}>
      <div className="container">
        <div className="couponMain">
          <div className="coupon_text">
            <p className="mobileTitle" style={{color: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>Take Advantage of our latest offer</p>
            <p className="coupon" style={{color: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>
              {data.sanityPages.coupon.title} <br className="coupon_break"/> <span className="mobileCouponType">{data.sanityPages.coupon.type}</span></p>
              <p className="coupon_disclaimer_mobile" style={{color: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>*Call office for more details.</p>
            <a href="#" style={{backgroundColor: data.sanityCompanyInfo.primarycolor.hex}} onClick={printCoupon}>Claim Offer</a>
          </div>
        </div>
        <div className="couponClaim">
            <p className="coupon_disclaimer" style={{color: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>*Call office for more details.</p>
          </div>
      </div>
    </div>


   {/*} <div className="container pageContent homepage">
          <div className="row">    
        <PortableText blocks={data.sanityPages._rawFirstcopy} />
      </div>
</div>*/}

<div className="servicesIcons" style={{ 
    position: 'relative',
    backgroundImage: `url(${data.sanityCompanyInfo.homepageservicebackground.asset.fluid.src})`,
    zIndex: 0,
    backgroundSize: 'cover',
    }}>
    <div style={{ 
        position: 'absolute', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%', 
        backgroundColor: `rgba(${data.sanityCompanyInfo.primarycolor.rgb.r},${data.sanityCompanyInfo.primarycolor.rgb.g},${data.sanityCompanyInfo.primarycolor.rgb.b},0.7)`,
        zIndex: -1 
    }}>
    </div>




      <div className="container pageContent homepage">
        <h2 style={{color: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>What We Do</h2>
        <div className="servicesIconsInner">
          <div className="servicesIconsInnerLeft">
              <div className="servicesIconsDetail" style={{backgroundColor: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>
                <a href={data.sanityPages.homeservicesIcon1.homeservicesLink} className="serviceLink" data-service-slug={`${data.sanityPages.homeservicesIcon1.homeservicesLink}`}>
                <div className="servicesIconsDetailInner">
                  <BackgroundImage 
                  style={{height: "70px", width: "70px", backgroundSize: "cover"}}
                  fluid={data.sanityPages.homeservicesIcon1.homeservicesIcon.asset.fluid}>
                  </BackgroundImage>
                  <h3>{data.sanityPages.homeservicesIcon1.homeservicesTitle}</h3>
                  <p>{data.sanityPages.homeservicesIcon1.homeservicesText}</p>
                </div>
                </a>
              </div>
              <div className="servicesIconsDetail" style={{backgroundColor: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>
                <a href={data.sanityPages.homeservicesIcon2.homeservicesLink} className="serviceLink" data-service-slug={`${data.sanityPages.homeservicesIcon2.homeservicesLink}`}>
                  <div className="servicesIconsDetailInner">
                    <BackgroundImage 
                    style={{height: "70px", width: "70px", backgroundSize: "cover"}}
                    fluid={data.sanityPages.homeservicesIcon2.homeservicesIcon.asset.fluid}>
                    </BackgroundImage>
                    <h3>{data.sanityPages.homeservicesIcon2.homeservicesTitle}</h3>
                  <p>{data.sanityPages.homeservicesIcon2.homeservicesText}</p>
                  </div>
                </a>
              </div>
          </div>
          <div className="servicesIconsInnerRight">
              <div className="servicesIconsDetail" style={{backgroundColor: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>
                  <a href={data.sanityPages.homeservicesIcon3.homeservicesLink} className="serviceLink" data-service-slug={`${data.sanityPages.homeservicesIcon3.homeservicesLink}`}>
                  <div className="servicesIconsDetailInner">
                      <BackgroundImage 
                      style={{height: "70px", width: "70px", backgroundSize: "cover"}}
                      fluid={data.sanityPages.homeservicesIcon3.homeservicesIcon.asset.fluid}>
                      </BackgroundImage>
                      <h3>{data.sanityPages.homeservicesIcon3.homeservicesTitle}</h3>
                  <p>{data.sanityPages.homeservicesIcon3.homeservicesText}</p>
                  </div>
                </a>
              </div>
              <div className="servicesIconsDetail" style={{backgroundColor: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>
                <a href={data.sanityPages.homeservicesIcon4.homeservicesLink} className="serviceLink" data-service-slug={`${data.sanityPages.homeservicesIcon4.homeservicesLink}`}>
                  <div className="servicesIconsDetailInner">
                    <BackgroundImage 
                    style={{height: "70px", width: "70px", backgroundSize: "cover"}}
                    fluid={data.sanityPages.homeservicesIcon4.homeservicesIcon.asset.fluid}>
                    </BackgroundImage>
                    <h3>{data.sanityPages.homeservicesIcon4.homeservicesTitle}</h3>
                  <p>{data.sanityPages.homeservicesIcon4.homeservicesText}</p>
                  </div>
                </a>
              </div>
          </div>
        </div>
        <a href="/our-services" className="serviceLink" data-service-slug={`${"/our-services"}`} style={{backgroundColor: data.sanityCompanyInfo.primarycolor.hex, color:data.sanityCompanyInfo.tertiaryaltcolor.hex }}>View Our Services</a>
      </div>
    </div>

    <div className="socialProof" style={{backgroundColor: data.sanityCompanyInfo.secondarycolor.hex, color:"#fff" }}>
      <div className="container">
        <div className="socialBlurb">
            {data.allSanitySocial.edges.map(({ node: social }) => (
          <div className="blurp">
            <div className="socialInfo">
              <h3>{social.socialproofNumber}</h3>
              <p>{social.socialproofTitle}</p>
            </div>
          </div>
             ))}
        </div>
      </div>
    </div>      

    <div className="row servicesRow">
      <div className="leftSection">
        <BackgroundImage 
        style={{height: "100%"}}
        fluid={data.sanityPages.serviceimage.asset.fluid}>
        </BackgroundImage>
      </div>
      <div className="rightSection" style={{ backgroundColor: data.sanityCompanyInfo.primarycolor.hex }}>
        <span className="rightSectionTitle"><h2 style={{ color: '#fff' }}>Why You Should Choose {data.sanityCompanyInfo.companyname}</h2></span>
        <span className="rightSectionText">
        <p style={{color: '#fff'}}><PortableText blocks={data.sanityPages._rawServices} /></p>
        </span>
        <div className="ourServiceUSP">
            <div className="three-columns">
                <div className="column column1">
                    <i className={"fa fa-" + data.sanityPages.usp1.icon} style={{ fontSize: '4em', color: data.sanityCompanyInfo.tertiarycolor.hex }}/>
                    <h2 style={{ color: data.sanityCompanyInfo.tertiarycolor.hex }}>{data.sanityPages.usp1.uspTitle}</h2>
                </div>
                <div className="column column2">
                    <i className={"fa fa-" + data.sanityPages.usp2.icon} style={{ fontSize: '4em', color: data.sanityCompanyInfo.tertiarycolor.hex }}/>
                    <h2 style={{ color: data.sanityCompanyInfo.tertiarycolor.hex }}>{data.sanityPages.usp2.uspTitle}</h2>
                </div>
                <div className="column column3">
                    <i className={"fa fa-" + data.sanityPages.usp3.icon} style={{ fontSize: '4em', color: data.sanityCompanyInfo.tertiarycolor.hex }}/>
                    <h2 style={{ color: data.sanityCompanyInfo.tertiarycolor.hex }}>{data.sanityPages.usp3.uspTitle}</h2>
                </div>
            </div>
        </div>
      </div>
    </div>
  <CTA />
  </Layout>
)}
 
export default IndexPage
